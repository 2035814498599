exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-us-jsx": () => import("./../../../src/pages/about-us.jsx" /* webpackChunkName: "component---src-pages-about-us-jsx" */),
  "component---src-pages-all-posts-jsx": () => import("./../../../src/pages/all-posts.jsx" /* webpackChunkName: "component---src-pages-all-posts-jsx" */),
  "component---src-pages-calories-counter-jsx": () => import("./../../../src/pages/calories-counter.jsx" /* webpackChunkName: "component---src-pages-calories-counter-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-fitness-jsx": () => import("./../../../src/pages/fitness.jsx" /* webpackChunkName: "component---src-pages-fitness-jsx" */),
  "component---src-pages-health-jsx": () => import("./../../../src/pages/health.jsx" /* webpackChunkName: "component---src-pages-health-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-partners-jsx": () => import("./../../../src/pages/partners.jsx" /* webpackChunkName: "component---src-pages-partners-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-terms-conditions-jsx": () => import("./../../../src/pages/terms-conditions.jsx" /* webpackChunkName: "component---src-pages-terms-conditions-jsx" */),
  "component---src-pages-tools-jsx": () => import("./../../../src/pages/tools.jsx" /* webpackChunkName: "component---src-pages-tools-jsx" */),
  "component---src-pages-weight-calculator-jsx": () => import("./../../../src/pages/weight-calculator.jsx" /* webpackChunkName: "component---src-pages-weight-calculator-jsx" */),
  "component---src-template-post-jsx": () => import("./../../../src/template/post.jsx" /* webpackChunkName: "component---src-template-post-jsx" */)
}

