import React from "react";
import { Link } from "gatsby";
import Letters from "../../images/logo/letters.png";

export default function Footer() {
    const footerNavs = [
        {
            href: "/about-us",
            name: "About Us",
        },
        {
            href: "/privacy-policy",
            name: "Privacy Policy",
        },
        {
            href: "/terms-conditions",
            name: "Terms and Conditions",
        },
        {
            href: "/contact",
            name: "Contact Us",
        },
    ];

    return (
        <footer className="bg-primary/20">
            <div className="max-w-[1240px] mx-auto px-4 md:px-5 lg:px-8 xl:px-0 py-5 md:py-16">
                <div className="grid grid-cols-12 gap-5 md:gap-7">
                    <div className="col-span-12 xl:col-span-6 lg:pr-12 xl:pr-28">
                        <img
                            src={Letters}
                            className="w-[180px] md:w-[220px] lg:w-[250px]"
                            alt="Letters"
                        />
                        <p className="leading-relaxed mt-5 md:mt-7 text-[15px]">
                            Hello from The Fit Flare, your dedicated wellness
                            ally. We're here to empower you with expert
                            guidance, inspiration, and encouragement across the
                            realms of health and fitness.
                        </p>
                        <div className="flex justify-start items-center mt-8">
                            <Link
                                to="/contact"
                                className="items-center px-4 py-2 text-sm font-medium text-center rounded-lg hover:bg-primary/90 text-white bg-primary"
                            >
                                Join Us
                            </Link>
                        </div>
                    </div>
                    <div className="col-span-12 lg:col-span-6 xl:col-span-2">
                        <ul className="">
                            {footerNavs.map((item, idx) => (
                                <li className="tracking-wide leading-7 pb-3">
                                    <Link
                                        className="hover:text-primary text-secondary text-base"
                                        key={idx}
                                        to={item.href}
                                    >
                                        {item.name}
                                    </Link>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="col-span-12 lg:col-span-6 xl:col-span-4">
                        <div className="text-sm ">
                            <span className="lg:pl-4 xl:pl-16 text-center lg:text-left block">
                                &copy; 2024
                                <Link
                                    to="/"
                                    className="font-medium text-primary hover:underline"
                                >
                                    &nbsp;The Fit Flare&nbsp;
                                </Link>
                                All rights reserved.
                            </span>
                            <p className="text-center lg:text-left lg:pl-4 xl:pl-16 pt-4 tracking-wide leading-6">
                                We're dedicated to support your health and
                                fitness journey with tips. Just a heads up,
                                We're not medical professionals. Our guidance is
                                meant to inform, not prescribe.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}
