import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import Logo from "../../images/logo/logo.png";

// const healthDropdown = [
//     {
//         label: "Products",
//         navs: [
//             {
//                 title: "Analytics",
//                 desc: "Duis aute irure dolor in reprehenderit",
//                 path: "javascript:void(0)",
//                 icon: (
//                     <svg
//                         xmlns="http://www.w3.org/2000/svg"
//                         fill="none"
//                         viewBox="0 0 24 24"
//                         strokeWidth={1.5}
//                         stroke="currentColor"
//                         className="w-6 h-6"
//                     >
//                         <path
//                             strokeLinecap="round"
//                             strokeLinejoin="round"
//                             d="M2.25 18L9 11.25l4.306 4.307a11.95 11.95 0 015.814-5.519l2.74-1.22m0 0l-5.94-2.28m5.94 2.28l-2.28 5.941"
//                         />
//                     </svg>
//                 ),
//             },
//             {
//                 title: "Reports",
//                 desc: "Duis aute irure dolor in reprehenderit",
//                 path: "javascript:void(0)",
//                 icon: (
//                     <svg
//                         xmlns="http://www.w3.org/2000/svg"
//                         viewBox="0 0 24 24"
//                         fill="currentColor"
//                         className="w-6 h-6"
//                     >
//                         <path d="M18.375 2.25c-1.035 0-1.875.84-1.875 1.875v15.75c0 1.035.84 1.875 1.875 1.875h.75c1.035 0 1.875-.84 1.875-1.875V4.125c0-1.036-.84-1.875-1.875-1.875h-.75zM9.75 8.625c0-1.036.84-1.875 1.875-1.875h.75c1.036 0 1.875.84 1.875 1.875v11.25c0 1.035-.84 1.875-1.875 1.875h-.75a1.875 1.875 0 01-1.875-1.875V8.625zM3 13.125c0-1.036.84-1.875 1.875-1.875h.75c1.036 0 1.875.84 1.875 1.875v6.75c0 1.035-.84 1.875-1.875 1.875h-.75A1.875 1.875 0 013 19.875v-6.75z" />
//                     </svg>
//                 ),
//             },
//         ],
//     },
//     {
//         label: "Resources",
//         navs: [
//             {
//                 title: "Blog",
//                 desc: "Duis aute irure dolor in reprehenderit",
//                 path: "javascript:void(0)",
//                 icon: (
//                     <svg
//                         xmlns="http://www.w3.org/2000/svg"
//                         viewBox="0 0 24 24"
//                         fill="currentColor"
//                         className="w-6 h-6"
//                     >
//                         <path d="M11.25 4.533A9.707 9.707 0 006 3a9.735 9.735 0 00-3.25.555.75.75 0 00-.5.707v14.25a.75.75 0 001 .707A8.237 8.237 0 016 18.75c1.995 0 3.823.707 5.25 1.886V4.533zM12.75 20.636A8.214 8.214 0 0118 18.75c.966 0 1.89.166 2.75.47a.75.75 0 001-.708V4.262a.75.75 0 00-.5-.707A9.735 9.735 0 0018 3a9.707 9.707 0 00-5.25 1.533v16.103z" />
//                     </svg>
//                 ),
//             },
//             {
//                 title: "Community",
//                 desc: "Duis aute irure dolor in reprehenderit",
//                 path: "javascript:void(0)",
//                 icon: (
//                     <svg
//                         xmlns="http://www.w3.org/2000/svg"
//                         viewBox="0 0 24 24"
//                         fill="currentColor"
//                         className="w-6 h-6"
//                     >
//                         <path d="M21.721 12.752a9.711 9.711 0 00-.945-5.003 12.754 12.754 0 01-4.339 2.708 18.991 18.991 0 01-.214 4.772 17.165 17.165 0 005.498-2.477zM14.634 15.55a17.324 17.324 0 00.332-4.647c-.952.227-1.945.347-2.966.347-1.021 0-2.014-.12-2.966-.347a17.515 17.515 0 00.332 4.647 17.385 17.385 0 005.268 0zM9.772 17.119a18.963 18.963 0 004.456 0A17.182 17.182 0 0112 21.724a17.18 17.18 0 01-2.228-4.605zM7.777 15.23a18.87 18.87 0 01-.214-4.774 12.753 12.753 0 01-4.34-2.708 9.711 9.711 0 00-.944 5.004 17.165 17.165 0 005.498 2.477zM21.356 14.752a9.765 9.765 0 01-7.478 6.817 18.64 18.64 0 001.988-4.718 18.627 18.627 0 005.49-2.098zM2.644 14.752c1.682.971 3.53 1.688 5.49 2.099a18.64 18.64 0 001.988 4.718 9.765 9.765 0 01-7.478-6.816zM13.878 2.43a9.755 9.755 0 016.116 3.986 11.267 11.267 0 01-3.746 2.504 18.63 18.63 0 00-2.37-6.49zM12 2.276a17.152 17.152 0 012.805 7.121c-.897.23-1.837.353-2.805.353-.968 0-1.908-.122-2.805-.353A17.151 17.151 0 0112 2.276zM10.122 2.43a18.629 18.629 0 00-2.37 6.49 11.266 11.266 0 01-3.746-2.504 9.754 9.754 0 016.116-3.985z" />
//                     </svg>
//                 ),
//             },
//         ],
//     },
//     {
//         label: "Company",
//         navs: [
//             {
//                 title: "About us",
//                 desc: "Duis aute irure dolor in reprehenderit",
//                 path: "javascript:void(0)",
//                 icon: (
//                     <svg
//                         xmlns="http://www.w3.org/2000/svg"
//                         viewBox="0 0 24 24"
//                         fill="currentColor"
//                         className="w-6 h-6"
//                     >
//                         <path
//                             fillRule="evenodd"
//                             d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm11.378-3.917c-.89-.777-2.366-.777-3.255 0a.75.75 0 01-.988-1.129c1.454-1.272 3.776-1.272 5.23 0 1.513 1.324 1.513 3.518 0 4.842a3.75 3.75 0 01-.837.552c-.676.328-1.028.774-1.028 1.152v.75a.75.75 0 01-1.5 0v-.75c0-1.279 1.06-2.107 1.875-2.502.182-.088.351-.199.503-.331.83-.727.83-1.857 0-2.584zM12 18a.75.75 0 100-1.5.75.75 0 000 1.5z"
//                             clipRule="evenodd"
//                         />
//                     </svg>
//                 ),
//             },
//             {
//                 title: "Careers",
//                 desc: "Duis aute irure dolor in reprehenderit",
//                 path: "javascript:void(0)",
//                 icon: (
//                     <svg
//                         xmlns="http://www.w3.org/2000/svg"
//                         viewBox="0 0 24 24"
//                         fill="currentColor"
//                         className="w-6 h-6"
//                     >
//                         <path
//                             fillRule="evenodd"
//                             d="M7.5 5.25a3 3 0 013-3h3a3 3 0 013 3v.205c.933.085 1.857.197 2.774.334 1.454.218 2.476 1.483 2.476 2.917v3.033c0 1.211-.734 2.352-1.936 2.752A24.726 24.726 0 0112 15.75c-2.73 0-5.357-.442-7.814-1.259-1.202-.4-1.936-1.541-1.936-2.752V8.706c0-1.434 1.022-2.7 2.476-2.917A48.814 48.814 0 017.5 5.455V5.25zm7.5 0v.09a49.488 49.488 0 00-6 0v-.09a1.5 1.5 0 011.5-1.5h3a1.5 1.5 0 011.5 1.5zm-3 8.25a.75.75 0 100-1.5.75.75 0 000 1.5z"
//                             clipRule="evenodd"
//                         />
//                         <path d="M3 18.4v-2.796a4.3 4.3 0 00.713.31A26.226 26.226 0 0012 17.25c2.892 0 5.68-.468 8.287-1.335.252-.084.49-.189.713-.311V18.4c0 1.452-1.047 2.728-2.523 2.923-2.12.282-4.282.427-6.477.427a49.19 49.19 0 01-6.477-.427C4.047 21.128 3 19.852 3 18.4z" />
//                     </svg>
//                 ),
//             },
//         ],
//     },
// ];
const Blogs = [
    {
        navs: [
            {
                title: "Health",
                desc: "Blogs about",
                path: "/health",
            },
            {
                title: "Fitness",
                desc: "Blogs about",
                path: "/fitness",
            },
        ],
    },
    // {
    //     label: "Health",
    //     navs: [
    //         {
    //             title: "Neuro",
    //             desc: "Blogs about",
    //             path: "javascript:void(0)",
    //         },
    //         {
    //             title: "Heart",
    //             desc: "Blogs about",
    //             path: "javascript:void(0)",
    //         },
    //         {
    //             title: "Bones",
    //             desc: "Blogs about",
    //             path: "javascript:void(0)",
    //         },
    //         {
    //             title: "Eyes",
    //             desc: "Blogs about",
    //             path: "javascript:void(0)",
    //         },
    //         {
    //             title: "Hairs",
    //             desc: "Blogs about",
    //             path: "javascript:void(0)",
    //         },
    //         {
    //             title: "Skin",
    //             desc: "Blogs about",
    //             path: "javascript:void(0)",
    //         },
    //         {
    //             title: "Nails",
    //             desc: "Blogs about",
    //             path: "javascript:void(0)",
    //         },
    //         {
    //             title: "Immune System",
    //             desc: "Blogs about",
    //             path: "javascript:void(0)",
    //         },
    //         {
    //             title: "Sexual",
    //             desc: "Blogs about",
    //             path: "javascript:void(0)",
    //         },
    //         {
    //             title: "View All",
    //             desc: "Blogs about",
    //             path: "/health",
    //         },
    //     ],
    // },
    // {
    //     label: "Fitness",
    //     navs: [
    //         {
    //             title: "Muscle Building",
    //             desc: "Blogs about",
    //             path: "javascript:void(0)",
    //         },
    //         {
    //             title: "Lean",
    //             desc: "Blogs about",
    //             path: "javascript:void(0)",
    //         },
    //         {
    //             title: "Bulking",
    //             desc: "Blogs about",
    //             path: "javascript:void(0)",
    //         },
    //         {
    //             title: "View All",
    //             desc: "Blogs about",
    //             path: "/fitness",
    //         },
    //     ],
    // },
];

export default function Navbar() {
    const [state, setState] = useState(false);
    const [drapdownState, setDrapdownState] = useState({
        isActive: false,
        idx: null,
    });

    // Replace javascript:void(0) paths with your paths
    const navigation = [
        { title: "Home", path: "/", isDrapdown: false },
        { title: "Tools", path: "/tools", isDrapdown: false },
        {
            title: "Topics",
            path: "javascript:void(0)",
            isDrapdown: true,
            navs: Blogs,
        },
        { title: "Our Partners", path: "/partners", isDrapdown: false },
        { title: "Contact Us", path: "/contact", isDrapdown: false },
    ];
    useEffect(() => {
        document.onclick = (e) => {
            const target = e.target;
            if (!target.closest(".nav-menu"))
                setDrapdownState({ isActive: false, idx: null });
        };
    }, []);

    return (
        <section className="md:sticky top-0 z-50">
            <nav
                className={`relative bg-NavBg w-full md:static md:text-sm ${
                    state ? "shadow-lg rounded-b-xl md:shadow-none" : ""
                }`}
            >
                <div className="items-center justify-between md:flex max-w-[1240px] mx-auto px-4 md:px-5 lg:px-8 xl:px-0">
                    <div className="flex items-center justify-between py-4 md:block">
                        <Link to="/" className="">
                            <img
                                className="object-cover h-full w-[150px] md:w-[170px] lg:w-[180px] xl:w-[190px]"
                                src={Logo}
                                alt="Logo"
                            />
                        </Link>
                        {/* Theme Toggler */}
                        <div className="md:hidden flex space-x-3">
                            {/* Theme Button Here aswell */}
                            {/* <div className="flex justify-center items-center">
                                <ThemeToggler>
                                    {({ theme, toggleTheme }) => {
                                        return (
                                            <button
                                                className="mx-4 text-primary hover:text-black/50 dark:hover:text-gray-200"
                                                type="button"
                                                onClick={() => {
                                                    toggleTheme(
                                                        darkMode
                                                            ? "light"
                                                            : "dark"
                                                    );
                                                    setDarkMode(!darkMode);
                                                }}
                                                aria-label="Toggle theme"
                                            >
                                                {!darkMode ? (
                                                    <BsFillBrightnessHighFill
                                                        className={"w-5 h-5"}
                                                    />
                                                ) : (
                                                    <BsFillMoonFill
                                                        className={"w-5 h-5"}
                                                    />
                                                )}
                                            </button>
                                        );
                                    }}
                                </ThemeToggler>
                            </div> */}
                            <button
                                className="text-gray-500 hover:text-gray-800"
                                onClick={() => setState(!state)}
                            >
                                {state ? (
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-6 w-6"
                                        viewBox="0 0 20 20"
                                        fill="currentColor"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                ) : (
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                        fill="currentColor"
                                        className="w-6 h-6"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            d="M3 6.75A.75.75 0 013.75 6h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 6.75zM3 12a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 12zm8.25 5.25a.75.75 0 01.75-.75h8.25a.75.75 0 010 1.5H12a.75.75 0 01-.75-.75z"
                                            clipRule="evenodd"
                                        />
                                    </svg>
                                )}
                            </button>
                        </div>
                    </div>
                    {/* Nav */}
                    <div
                        className={`nav-menu flex pb-6 mt-6 md:block md:pb-0 md:mt-0 ${
                            state ? "block" : "hidden"
                        }`}
                    >
                        <ul className="items-center space-y-6 md:flex md:space-x-6 md:space-y-0">
                            {navigation.map((item, idx) => {
                                return (
                                    <li key={idx}>
                                        {item.isDrapdown ? (
                                            <button
                                                className="w-full text-base font-medium flex items-center justify-between gap-[2px] text-secondary hover:text-primary"
                                                onClick={() =>
                                                    setDrapdownState({
                                                        idx,
                                                        isActive:
                                                            !drapdownState.isActive,
                                                    })
                                                }
                                            >
                                                {item.title}
                                                {drapdownState.idx === idx &&
                                                drapdownState.isActive ? (
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 20 20"
                                                        fill="currentColor"
                                                        className="w-5 h-5"
                                                    >
                                                        <path
                                                            fillRule="evenodd"
                                                            d="M14.77 12.79a.75.75 0 01-1.06-.02L10 8.832 6.29 12.77a.75.75 0 11-1.08-1.04l4.25-4.5a.75.75 0 011.08 0l4.25 4.5a.75.75 0 01-.02 1.06z"
                                                            clipRule="evenodd"
                                                        />
                                                    </svg>
                                                ) : (
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        viewBox="0 0 20 20"
                                                        fill="currentColor"
                                                        className="w-5 h-5"
                                                    >
                                                        <path
                                                            fillRule="evenodd"
                                                            d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                                                            clipRule="evenodd"
                                                        />
                                                    </svg>
                                                )}
                                            </button>
                                        ) : (
                                            <Link
                                                to={item.path}
                                                onClick={() => {
                                                    setDrapdownState({
                                                        isActive: false,
                                                        idx: null,
                                                    });
                                                    setState(false);
                                                }}
                                                className="block text-base font-medium text-secondary hover:text-primary"
                                            >
                                                {item.title}
                                            </Link>
                                        )}
                                        {item.isDrapdown &&
                                        drapdownState.idx === idx &&
                                        drapdownState.isActive ? (
                                            <div className="mt-6 bg-white md:absolute md:border-y md:shadow-md md:mt-0 rounded-md w-[200px]">
                                                <ul className="mx-auto p-4">
                                                    {item?.navs.map(
                                                        (dropdownItem, idx) => (
                                                            <li key={idx}>
                                                                <p className="text-base font-medium">
                                                                    {
                                                                        dropdownItem.label
                                                                    }
                                                                </p>
                                                                {/* Options Links */}
                                                                <ul className="">
                                                                    {dropdownItem.navs.map(
                                                                        (
                                                                            navItem,
                                                                            idx
                                                                        ) => (
                                                                            <li
                                                                                key={
                                                                                    idx
                                                                                }
                                                                                className="my-1"
                                                                            >
                                                                                <Link
                                                                                    to={
                                                                                        navItem.path
                                                                                    }
                                                                                    onClick={() => {
                                                                                        setDrapdownState(
                                                                                            {
                                                                                                isActive: false,
                                                                                                idx: null,
                                                                                            }
                                                                                        );
                                                                                        setState(
                                                                                            false
                                                                                        );
                                                                                    }}
                                                                                    className="gap-3 items-center"
                                                                                >
                                                                                    <div className="">
                                                                                        <span
                                                                                            className={`text-secondary hover:bg-primary hover:text-white font-medium text-base block pl-2 py-1 rounded-md ${
                                                                                                navItem.title ===
                                                                                                "View All"
                                                                                                    ? "font-semibold"
                                                                                                    : ""
                                                                                            }`}
                                                                                        >
                                                                                            {
                                                                                                navItem.title
                                                                                            }
                                                                                        </span>
                                                                                        {/* <p className="text-sm text-gray-600 group-hover:text-gray-800 mt-1">
                                                                                            {
                                                                                                navItem.desc
                                                                                            }
                                                                                        </p> */}
                                                                                    </div>
                                                                                </Link>
                                                                            </li>
                                                                        )
                                                                    )}
                                                                </ul>
                                                            </li>
                                                        )
                                                    )}
                                                    <div className="z-10">
                                                        <div className="">
                                                            <Link
                                                                to="/all-posts"
                                                                onClick={() => {
                                                                    setDrapdownState(
                                                                        {
                                                                            isActive: false,
                                                                            idx: null,
                                                                        }
                                                                    );
                                                                    setState(
                                                                        false
                                                                    );
                                                                }}
                                                                className=" hover:bg-primary hover:text-white font-semibold text-base block pl-2 py-1 rounded-md"
                                                            >
                                                                View All
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </ul>
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                    </li>
                                );
                            })}
                            {/* Theme Button Here */}
                            {/* <div className="flex justify-center items-center">
                                <ThemeToggler>
                                    {({ theme, toggleTheme }) => {
                                        return (
                                            <button
                                                className="mx-4 text-primary hover:text-black/50 dark:hover:text-gray-200"
                                                type="button"
                                                onClick={() => {
                                                    toggleTheme(
                                                        darkMode
                                                            ? "light"
                                                            : "dark"
                                                    );
                                                    setDarkMode(!darkMode);
                                                }}
                                                aria-label="Toggle theme"
                                            >
                                                {!darkMode ? (
                                                    <BsFillBrightnessHighFill
                                                        className={"w-5 h-5"}
                                                    />
                                                ) : (
                                                    <BsFillMoonFill
                                                        className={"w-5 h-5"}
                                                    />
                                                )}
                                            </button>
                                        );
                                    }}
                                </ThemeToggler>
                            </div> */}
                        </ul>
                    </div>
                </div>
            </nav>
            {state ? (
                <div
                    className="z-50 w-screen bg-black/20 md:hidden"
                    onClick={() => setState(false)}
                ></div>
            ) : (
                ""
            )}
        </section>
    );
}
